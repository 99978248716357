<template>
  <main>
    <loading-overlay
        :active="apiStateLoading"
        :is-full-page="true"
        loader="bars"
      />
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.moduleRoleList')}} - SIGET-MOBILE</b>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader color="dark" text-color="white">
              <h5>{{$t('label.filter')}}</h5>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6" lg="3">
                  <CSelect
                    :label="$t('label.cargoType')"
                    :placeholder="$t('label.select')"
                    :horizontal="{label:'col-sm-9 col-lg-5',input:'col-sm-11 col-lg-7'}"
                    addLabelClasses="text-right"
                    :options="computedTpCargoList"
                    :value="cargoTypeSelect"
                    @change="listCargo($event)"
                  />
                </CCol>
                <CCol sm="6" lg="3">
                  <CSelect
                    :label="$t('label.stowageRole')"
                    :placeholder="$t('label.select')"
                    :horizontal="{label:'col-sm-9 col-lg-5',input:'col-sm-11 col-lg-7'}"
                    addLabelClasses="text-right"
                    :options="computedTpRolList"
                    :value="rolTypeSelect"
                    @change="listRol($event)"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader color="secondary" text-color="white">
              <h5 class="float-left">{{$t('label.moduleList')}}</h5>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedSigetMobileList"
                    :fields="fields"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    small
                    sorter
                    pagination
                  >
                    <template #loading>
                          <loading/>
                    </template>
                    <template #checked-header>
                      <td style="border-top:none">
                        <CInputCheckbox
                          custom
                          class="checkbook-filter mr-2"
                          :checked="selectedAllModulo"
                          @update:checked="selectAllModulos($event)"
                        />
                      </td>
                    </template>
                    <template #checked="{item}">
                      <td>
                        <CInputCheckbox
                          custom
                          class="checkbook-filter ml-1"
                          :checked="item.checked"
                          @update:checked="addRemoveId(item,'padre')"
                        />
                      </td>
                    </template>
                    <template #show_details="{item}">
                      <td class="centen-cell">
                        <CButton
                          color="watch"
                          square
                          v-c-tooltip="{
                          content: $t('label.toView')+' '+$t('label.level'),
                            placement: 'top-end',
                          }"
                          size="sm"
                          @click="toggleLevel1(item)" 
                          v-if="item.ModuleDeviceChildJson.length > 0"
                        >
                          <CIcon name="eye" />
                        </CButton>
                      </td>
                    </template>
                    <template #details="{item}">
                      <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                        > 
                        <CCardBody>
                          <CCol sm="12" lg="12">
                            <!-- Datatable sudmodule -->
                            <dataTableExtended
                              class="align-center-row-datatable table-lv-2"
                              :items="computedSigetMobileSubList"
                              :fields="fields2"
                              :items-per-page="5"
                              sorter
                              pagination
                              :table-filter="tableText.tableFilterText"
                              :items-per-page-select="tableText.itemsPerPageText"
                              :noItemsView="tableText.noItemsViewText"                                    
                              >
                              <template #loading>
                                    <loading/>
                              </template>
                              <template #checked="{item}">
                                <td>
                                  <CInputCheckbox
                                    custom
                                    class="checkbook-filter ml-1"
                                    :checked="item.checked"
                                    @update:checked="addRemoveId(item,'hijo')"
                                  />
                                </td>
                              </template>
                            </dataTableExtended>
                          </CCol>
                        </CCardBody>
                      </CCollapse>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
          <CCol sm="12" class="text-right">
            <CButton color="add" :disabled="isSubmit" @click="saveChanges()">
              <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
            </CButton>
          </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</main>
</template>
<script>
import GeneralMixin from '@/_mixins/general'

function data() {
  return {
    idCollapse:'',
    GpoCargo: {},
    GpoCargoId:'',
    StowageRoleId:'',
    cargoTypeSelect: '',
    rolTypeSelect: '',
    group1: [],
    group2: [],
    selectedModulo: [],
    selectedAllModulo: false,
    itemsNivelI: [],
    itemsNivelII: [],
    itemsRols:[],
    apiStateLoading: false, 
    collapseDuration: 0,   
    isSubmit:false,
    Collapse: false,
    ModuleDeviceId:'',
    Status:0,
    arrIds:[],
    collapseOpen: '',
  };
}

//methods

function closeShow(item, level) {
  let moduleOpen;
  if (level == 1) {
    moduleOpen = this.computedSigetMobileList.find((val) => val._toggled == true);
  } else if (level == 2) {
    moduleOpen = this.computedSigetMobileSubList.find((val) => val._toggled == true);
  }
  if (moduleOpen != undefined && moduleOpen.ModuleDeviceId != item.ModuleDeviceId) {
    moduleOpen._toggled = !moduleOpen._toggled;
  }
}

async function toggleLevel1(item) {
  this.apiStateLoading = true;
  await this.closeShow(item, 1);

  const modulePadre = this.itemsNivelI.find(
    (val) => val.ModuleDeviceId === item.ModuleDeviceId
  );
 
  this.itemsNivelII = modulePadre.ModuleDeviceChildJson;

  this.collapseOpen = modulePadre?.ModuleDeviceId ? modulePadre.ModuleDeviceId : '';

  item._toggled = !item._toggled;
  if (item._toggled == true) {
      this.idCollapse = item.ModuleDeviceId;
    }else{ 
      this.idCollapse = '';
    };
  
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

function selectAllModulos(event) {
  this.arrIds = [];

  if (event) {
    this.selectedAllModulo = true;
    //SELECCIONA TODOS LOS ITEM NIVEL I
    this.itemsNivelI.map((item) => {
      this.arrIds.push({id: item.ModuleDeviceId, role: item.ModuleDeviceRoleId ? item.ModuleDeviceRoleId : ''});  
    });
    //SELECCIONA TODOS LOS ITEM NIVEL II
    this.itemsNivelII.map((item) => {
      this.arrIds.push({id: item.ModuleDeviceId, role: item.ModuleDeviceRoleId ? item.ModuleDeviceRoleId : ''});  
    });
  }else{
    this.selectedAllModulo = false;
  }
}


function addRemoveId(item, tipo) {

  
  let bool = this.arrIds.filter((e) => e.id == item.ModuleDeviceId)

  if( bool.length == 0 ){
    //ADD
    this.arrIds.push({id: item.ModuleDeviceId, role: ''});
    
    //SI SE TILDA UN PADRE SE DEBEN MARCAR TODOS LOS HIJOS
    if ( tipo == 'padre'){
      this.itemsNivelII.map((j, index) => {
        //this.arrIds = this.arrIds.filter((e) => e.id !== j.ModuleDeviceId);
        this.arrIds.push({id: j.ModuleDeviceId, role: ''});
      });
    }

    //SI SE TILDA UN HIJO SE DEBE MARCAR EL PADRE
    if ( tipo == 'hijo' && this.collapseOpen != ''){
      let aux = this.arrIds.filter((e) => e.id == this.collapseOpen)

      aux.length == 0 && this.arrIds.push({id: this.collapseOpen, role: ''});
    }
  }
  else{
    //DELETE
    this.arrIds = this.arrIds.filter((e) => e.id !== item.ModuleDeviceId);

        
    //SI SE DESTILDA UN PADRE SE DEBE DESMARCAN LOS HIJOS
    if ( tipo == 'padre' && this.collapseOpen != ''){
      
      this.itemsNivelII.map((j, index) => {
        this.arrIds = this.arrIds.filter((e) => e.id !== j.ModuleDeviceId);
      });
    }
  }

  this.selectedAllModulo = false;  
}

function cleanDataSelect(){
  this.arrIds = [];
  this.itemsNivelI = [];
  this.selectedAllModulo = false;
  this.idCollapse=false;
}

function listCargo(event) {
  this.cargoTypeSelect = event.target.value;
  this.getRolList();
}

function listRol(event) {
  this.rolTypeSelect = event.target.value;
  this.getModuleDeviceList();
}

function getRolList() {
  if (this.cargoTypeSelect!='') {
    this.apiStateLoading = true;
    this.$http
    .get('StowageRole-By-GpoCargoId', {GpoCargoId: this.cargoTypeSelect})
    .then((response) => {
      if (response.data.data.length != 0) {
        this.group2 = response.data.data;
        this.rolTypeSelect = this.group2[0].StowageRoleId;
        this.apiStateLoading = false;
        this.getModuleDeviceList();
      }
    })
    .catch((e) => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    })  
  }else{
    this.group2 = [];
    this.itemsNivelI = [];
  }
}
function ListCargoType() {
  this.apiStateLoading = true;
  this.$http
    .get('GpoCargoMobile-list')
    .then(response => {
      let list = response.data.data;
      if (list.length != 0) {
        this.group1 = list;
        this.cargoTypeSelect = this.group1[0].GpoCargoId;
        this.getRolList();
      }
    })
    .catch((e) => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    })  
}

function getModuleDeviceList() {
  if ((this.cargoTypeSelect!='') && (this.rolTypeSelect!='')) {
    this.apiStateLoading = true;
    this.cleanDataSelect();
    this.$http
    .get('ModuleDeviceGpoCargoRole-list', {GpoCargoId: this.cargoTypeSelect, StowageRoleId: this.rolTypeSelect})
    .then((response) => {
      let list = response.data.data;
      this.itemsNivelI = response.data.data;
      this.apiStateLoading = false;
      
      list.map((item) => {
        if ( item.ModuleDeviceRoleId !== null ) {
          //PADRES
          this.arrIds.push({id: item.ModuleDeviceId, role: item.ModuleDeviceRoleId});
          
          (item.ModuleDeviceChildJson).map((e) => {
            if ( e.ModuleDeviceRoleId !== null ) {  
              //HIJOS           
              this.arrIds.push({id: e.ModuleDeviceId, role: e.ModuleDeviceRoleId});
            }
          });
        }
      });
    })
    .catch((e) => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    })
  }
  else{
    this.itemsNivelI = [];
  }
}

function saveChanges(){
  let res = [];
  this.apiStateLoading = true;

  let json = [];

  this.arrIds.map((e) => {
    json.push({
      ModuleDeviceId: e.id,
      StowageRoleId: this.rolTypeSelect,})
  })

  json=this.arrIds.length==0 ? {StowageRoleId: this.rolTypeSelect, ModuleDeviceId: ""} : json

  this.$http.post("ModuleDeviceRole-insert", json, { root: 'ModuleDeviceRoleJson' })
    .then((response) => {
      res = [...response.data.data];

      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: res[0].Response,
        type: 'success',
      });

      this.getModuleDeviceList();
      this.apiStateLoading = false;

      this.cleanDataSelect();
    })
    .catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: 'error',
      });
      this.apiStateLoading = false;
    });
}

function computedTpCargoList(){
  if(this.group1.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.group1.map(function(e){
      chart.push({
        label: e.GpoCargoName,
        value: e.GpoCargoId,
      })    
    })
    return chart;
  }
}

function computedTpRolList(){
  if(this.group2.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.group2.map(function(e){
      chart.push({
        label: e.StowageRoleName,
        value: e.StowageRoleId,
      })    
      })
      return chart;
  }
}

 function computedSigetMobileList() {
  if (this.itemsNivelI.length > 0) {
    return this.itemsNivelI.map((item) => {

      if (this.idCollapse==item.ModuleDeviceId) {
        this.itemsNivelII = item.ModuleDeviceChildJson;
      };

      let checkeds = this.arrIds.filter((e) => e.id == item.ModuleDeviceId);

      return {
        ...item,
        _classes:'table-dark',
        _toggled: this.idCollapse ? (this.idCollapse==item.ModuleDeviceId ? true : false) : false,
        checked: (checkeds.length > 0),
      };
    });
  }
}

function computedSigetMobileSubList() {
  if (this.itemsNivelII.length > 0) {
    return this.itemsNivelII.map((item) => {
      let checkeds = this.arrIds.filter((e) => e.id == item.ModuleDeviceId);

      return {
        ...item,
        _toggled: false,
        checked: (checkeds.length > 0),
      };
    });
  }
}

function fields () {
  return[
    { key: 'checked',label: 'Sel',sorter: false, filter: false,_style:'width:3%;',},
    { key: 'ModuleNameDsEs',label: this.$t('label.ModuleName')+' '+'(ES)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'ModuleNameDsEn',label: this.$t('label.ModuleName')+' '+'(EN)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'ModuleRoute',label: this.$t('label.route'),_style:'width:25%;text-align:center;', _classes:"center-cell", },
    { key: 'Navegation',label: this.$t('label.navigation'),_style:'width:30%;text-align:center;', _classes:'center-cell',},
    { key: 'show_details',label: '',_style: 'min-width:45px;', sorter: false,}
  ]
}

function fields2 () {
  return[
    { key: 'checked',label:'',sorter: false,_style:'width:3%;',},
    { key: 'ModuleNameDsEs',label: this.$t('label.SubModuleName')+' '+'(ES)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'ModuleNameDsEn',label: this.$t('label.SubModuleName')+' '+'(EN)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'ModuleRoute',label: this.$t('label.route'),_style:'width:25%;text-align:center;', _classes:"center-cell", },
    { key: 'Navegation',label: this.$t('label.navigation'),_style:'width:30%;text-align:center;', _classes:'center-cell',},
  ]
}




export default {
  name: 'module-rol-siget-mobile-index',
  components: {},
  data,
  mixins:[GeneralMixin],
  methods: {
    toggleLevel1,
    selectAllModulos,
    saveChanges,
    listCargo,
    listRol,
    getRolList,
    ListCargoType,
    getModuleDeviceList,
    addRemoveId,
    cleanDataSelect,
    closeShow,
  },
  computed: {
    computedSigetMobileList,
    computedSigetMobileSubList,
    fields,
    fields2,
    computedTpCargoList,
    computedTpRolList
  },
  mounted: ListCargoType, 
  
}
</script>

<style lang="scss">

.center-cell {
  text-align: center;
  vertical-align: middle;
}

</style>



